.animate-credits {
  animation: pulse 3s ease-in-out, rainbow-color 3s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rainbow-color {
  0% {
    color: #C8FAD6;
  }
  20% {
    color: #5BE49B;
  }
  40% {
    color: #00A76F;
  }
  60% {
    color: #637381;
  }
  80% {
    color: #454F5B;
  }
  100% {
    color: #1C252E;
  }
}
